import React, { useState, useEffect } from 'react';
import ContentModal from './ContentModal';
import './SidebarStyles.css';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  StopOutlined,
  YoutubeOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import { Button, Menu, Modal } from 'antd';

const Sidebar = ({ 
  isOpen, 
  toggleSidebar, 
  accessToken, 
  meetingId,
  startVideoContentSharing,
  stopVideoContentSharing,
  isVideoContentShared,
  myTeam,
  startScreenSharing,
  stopScreenSharing
}) => {
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [isIframeModalVisible, setIsIframeModalVisible] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeTitle, setIframeTitle] = useState('');

  const handleOpenIframeModal = (url) => {
    startScreenSharing();
    setIframeUrl(url);
    setIframeTitle("Annotate");
    setIsIframeModalVisible(true);
  };

  const handleCloseIframeModal = () => {
    stopScreenSharing();
    setIsIframeModalVisible(false);
    setIframeUrl('');
    setIframeTitle('');
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const openContentModal = (type) => {
    setModalContent(type);
    setIsContentModalOpen(true);
    setSearchQuery("");
  };

  const closeModal = () => {
    setModalContent("");
    setIsContentModalOpen(false);
    setSearchQuery("");
  };

  const handleItemClick = (content) => {
    let contentUrl;
    if(content.type === 'youtube' || content.type === 'video'){
      contentUrl = content.url;
      startVideoContentSharing(contentUrl);
    }
    else{
      alert("File-type not supported in current version!");
      return;
    }
    closeModal();
    toggleSidebar()
  };

  return (
    <>
    <Button
      type="primary"
      onClick={toggleSidebar}
      className="sidebar-toggle"
      >
      {isOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </Button>
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-content">
        <Menu mode="inline" theme="dark" defaultSelectedKeys={[]}>
          {isVideoContentShared?
            <Menu.Item className='danger' key="stopVideoContentSharing" onClick={stopVideoContentSharing} icon={<StopOutlined />}>
              {"Stop Video Sharing"}
            </Menu.Item>:null}
          {!isVideoContentShared?
            <>
              <Menu.Item key={"studentVideos"} onClick={() => openContentModal('Player Videos')} icon={<VideoCameraOutlined />}>
                {"Player Videos"}
              </Menu.Item>
              <Menu.Item key={"Youtube"} onClick={() => openContentModal("Youtube")} icon={<YoutubeOutlined />}>
                {"Youtube"}
              </Menu.Item>
            </>
           :null}
        </Menu>
      </div>
      <Modal
        title={`${iframeTitle}`}
        open={isIframeModalVisible}
        onCancel={handleCloseIframeModal}
        footer={null}
        width={800}
        className="iframeModal"
      >
        <iframe
          title="Video Annotation"
          src={iframeUrl}
          className="modalIframe"
        ></iframe>
      </Modal>
      {isContentModalOpen && <ContentModal 
        handleSearchInputChange={handleSearchInputChange} 
        closeModal={closeModal} 
        handleItemClick={handleItemClick} 
        searchText={searchQuery}
        modalContent={modalContent}
        myTeam={myTeam}
        meetingId={meetingId}
        accessToken={accessToken}
        handleOpenIframeModal={handleOpenIframeModal}
        handleCloseIframeModal={handleCloseIframeModal}
        />}
    </div>
    </>
  );
};

export default Sidebar;