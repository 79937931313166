import React, { useRef, useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { JaaSMeeting, JitsiMeeting } from "@jitsi/react-sdk";
import { Buffer } from "buffer";
import axios from "axios";
import "./style.css";
import { AUTH_API, JITSI_DOMAIN, RETURN_URL } from "./config";
import Sidebar from "./components/Sidebar";
import { Alert, notification } from 'antd';
import {
  AudioMutedOutlined,
} from '@ant-design/icons';

const Room = () => {
  const [antdNotificationApi, antdNotificationContextHolder] = notification.useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const meetingId = searchParams.get("meeting_id").includes("M-") ? searchParams.get("meeting_id").slice(2) : searchParams.get("meeting_id") ;
  const meetingName = searchParams.get("meeting_name");
  const userToken = searchParams.get("userToken");
  const user_name = searchParams.get("user_name");
  const [dualMeetMode, setDualMeetMode] = useState(null);

  const [loadMeet1, setLoadMeet1] = useState(false);
  const [loadMeet2, setLoadMeet2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidMeet, setIsValidMeet] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [userName, setUserName] = useState(user_name || "No-name-user");
  const [localUserId, setLocalUserId] = useState(null);

  const accessToken = userToken || null;

  const apiRef = useRef();
  const [isVideoContentShared, setIsVideoContentShared] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [myTeam, setMyTeam] = useState(null);
  const [screenSharingStatus, setScreenSharingStatus] = useState(false);
  const [showMutedAlert, setShowMutedAlert] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    isValidToken()
      .then((response) => {
        const data = response.data;
        if(data.isValidAccess === true){
          if(data.userName && data.userName.length > 0) {
            setUserName(data.userName);
          }
          setIsModerator(data.userRole === "moderator" ? true : false);
          setIsLoading(false);
          setIsValidMeet(data.isValidAccess);  
        } else {
          setIsLoading(false);
          setIsValidMeet(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsValidMeet(false);
      });
    
    if(myTeam === null){
      getMyTeam()
        .then((response) => {
          const team = response?.data || null;
          if(team.length > 0 && team[0].student_id > 0){
            setMyTeam(team);
          }
        })
        .catch((error) => {
          setMyTeam([]);
        });
    }
  }, []);

  const getMyTeam = async () => {
    let data = {
      "meetingId": meetingId,
      token : accessToken
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${AUTH_API}/api/get_my_team_for_vc`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return axios.request(config);
  };

  const isValidToken = async () => {
    let data = {
      "meetingId": meetingId,
      token : accessToken
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${AUTH_API}/api/is-valid-meeting`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return axios.request(config);
  };

  const updateMeetingStatus = async () => {
    let data = {
      "meetingId": meetingId,
      token : accessToken
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${AUTH_API}/api/update-meeting-end`, 
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return axios.request(config);
  };

  let moderatorToolbarButtons = [
    "camera",
    "chat",
    "closedcaptions",
    "desktop",
    "download",
    "embedmeeting",
    "etherpad",
    "feedback",
    "filmstrip",
    "fullscreen",
    "hangup",
    "help",
    "highlight",
    "invite",
    "linktosalesforce",
    "livestreaming",
    "microphone",
    "noisesuppression",
    "participants-pane",
    "profile",
    "raisehand",
    "recording",
    "security",
    "select-background",
    "settings",
    "shareaudio",
    "sharedvideo",
    "shortcuts",
    "stats",
    "tileview",
    "toggle-camera",
    "videoquality",
    "whiteboard",
  ];
  let participantToolbarButtons = [
    "camera",
    "chat",
    "microphone",
    "raisehand",
    "select-background",
    "tileview",
    "toggle-camera",
    "videoquality",
    "hangup",
  ];

  let cameraOnlyToolbarButtons = [
    "camera",
    "select-background",
    "tileview",
    "toggle-camera",
    "videoquality",
    "hangup",
  ];

  const handleApiReady = (apiObj) => {
    apiRef.current = apiObj;
    if(!dualMeetMode){
      apiRef.current.executeCommand('displayName', userName);
    }
    apiRef.current.on("participantJoined", () => {
      apiObj.getRoomsInfo().then((rooms) => {
        console.log(rooms, moderator);
      });
      console.log("apiObj:", apiObj);
    });
    
    //Change Toolbar when local participant role changed to moderator
    apiRef.current.on("participantRoleChanged", (payload) => {
      if(payload.role === 'moderator') {
        setIsModerator(true)
        apiRef.current.executeCommand('overwriteConfig',
          {
            toolbarButtons: moderatorToolbarButtons
          }
        );
      }
    });

    apiRef.current.on("screenSharingStatusChanged", handleScreenSharingStatusChanged);
    apiRef.current.on("audioMuteStatusChanged", handleAudioMuteStatusChanged);

    console.log("=======================", dualMeetMode);
    if(dualMeetMode){
      apiRef.current.on("videoConferenceJoined", handleVideoConferenceJoined);
    }
    
  };

  const handleVideoConferenceJoined = (info) => {
    setLocalUserId(info.id);
    apiRef.current.executeCommand('pinParticipant', info.id );
  };

  const handleMutedAlertClose = () => {
    setShowMutedAlert(false);
  };

  const handleAudioMuteStatusChanged = (obj) => {
    setShowMutedAlert(obj.muted);
    const notificationKey = `audioMuteNotification${Date.now()}`;
    if(obj.muted === true){
      antdNotificationApi.error({
        maxCount:1,
        placement: 'bottomLeft',
        icon: <AudioMutedOutlined/>,
        duration:30000,
        message: 'Muted!',
        description:
          'Your mic is muted!',
        className: 'redNotification',
        style: {
          width: 200,
        },
        onClose: () => handleMutedAlertClose()
      });
    }
  };

  const handleScreenSharingStatusChanged = (obj) => {
    setScreenSharingStatus(obj.on);
  };

  const handleReadyToClose = () => {
    if (isModerator) {
      updateMeetingStatus()
      .then((response) => { 
        setIsModerator(false);
        apiRef.current.executeCommand("endConference");
        setTimeout(() => {
          window.location.replace(RETURN_URL);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
      
    } else {
      setTimeout(() => {
        window.location.replace(RETURN_URL);
      }, 2000);
    }
  };

  const handleJitsiIFrameRef1 = (iframeRef) => {
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "100%";
  };

  const renderSpinner = () => (
    <div
      style={{
        fontFamily: "sans-serif",
        textAlign: "center",
      }}
    >
      Loading..
    </div>
  );

  const invalidMeet = (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <span style={{ fontSize: "xxx-large" }}> {isLoading ? "Loading....." : "Invalid Meeting"} </span>
    </div>
  );

  const validMeet = (
    <JitsiMeeting
      domain={JITSI_DOMAIN}
      roomName={meetingId}
      spinner={renderSpinner}
      configOverwrite={{
        prejoinConfig: {
          enabled: userName.length > 0 ? false : true,
        },
        deeplinking: {
          disabled: true,
        },
        startWithVideoMuted: true,
        subject: meetingName,
        hideConferenceSubject: false,
        toolbarButtons: isModerator
          ? moderatorToolbarButtons
          : participantToolbarButtons,
      }}
      onApiReady={(externalApi) => handleApiReady(externalApi)}
      onReadyToClose={handleReadyToClose}
      //getIFrameRef={handleJitsiIFrameRef1}
    />
  );

  const meetForCamera1 = (
    <JitsiMeeting
      domain={JITSI_DOMAIN}
      roomName={meetingId}
      spinner={renderSpinner}
      configOverwrite={{
        prejoinConfig: {
          enabled: userName.length > 0 ? false : true,
        },
        deeplinking: {
          disabled: true,
        },
        startWithVideoMuted: true,
        startWithAudioMuted: true,
        subject: meetingName,
        hideConferenceSubject: false,
        toolbarButtons: cameraOnlyToolbarButtons,
      }}
      userInfo = {{
        displayName: 'Camera 1'
      }}
      onApiReady={(externalApi) => handleApiReady(externalApi)}
      onReadyToClose={handleReadyToClose}
      //getIFrameRef={handleJitsiIFrameRef1}
    />
  );

  const meetForCamera2 = (
    <JitsiMeeting
      domain={JITSI_DOMAIN}
      roomName={meetingId}
      spinner={renderSpinner}
      configOverwrite={{
        prejoinConfig: {
          enabled: userName.length > 0 ? false : true,
        },
        deeplinking: {
          disabled: true,
        },
        startWithVideoMuted: true,
        startWithAudioMuted: true,
        subject: meetingName,
        hideConferenceSubject: false,
        toolbarButtons: cameraOnlyToolbarButtons,
      }}
      userInfo = {{
        displayName: 'Camera 2'
      }}
      onApiReady={(externalApi) => handleApiReady(externalApi)}
      onReadyToClose={handleReadyToClose}
      //getIFrameRef={handleJitsiIFrameRef1}
    />
  );

  const startVideoContentSharing = async (url) => {
    apiRef.current.executeCommand('startShareVideo', `${url}`);
    setIsVideoContentShared(true);
  }
  const stopVideoContentSharing = async () => {
    apiRef.current.executeCommand('stopShareVideo');
    setIsVideoContentShared(false);
  }

  const startScreenSharing = async () => {
    if(screenSharingStatus === false){
      apiRef.current.executeCommand('toggleShareScreen');
    }
  }
  const stopScreenSharing = async () => {
    if(screenSharingStatus === true){
      apiRef.current.executeCommand('toggleShareScreen');
    }
  }
  if(dualMeetMode === null){
    return (
      <div className="container" id="dualMeetContainer">
          <div className="column">
            <button className="loadCameraButton" onClick={() => setDualMeetMode(true)}>Go Dual-Meet</button>
          </div>
          <div className="column">
            <button className="loadCameraButton" onClick={() => setDualMeetMode(false)}>Go Normal-meet</button>
          </div>
      </div>
    )
  }

  return (
    <>
      {dualMeetMode &&
        <div className="container" id="dualMeetContainer">
            <div className="column">
              {loadMeet1 ? meetForCamera1 : <button className="loadCameraButton" onClick={() => setLoadMeet1(true)}>Load Camera 1</button>}
            </div>
            <div className="column">
              {loadMeet2 ? meetForCamera2 : <button className="loadCameraButton" onClick={() => setLoadMeet2(true)}>Load Camera 2</button>}
            </div>
        </div>}
      {isValidMeet && isModerator && !dualMeetMode? 
        <Sidebar 
        isVideoContentShared={isVideoContentShared} 
        startVideoContentSharing={startVideoContentSharing}
        stopVideoContentSharing={stopVideoContentSharing}
        isOpen={isSidebarOpen} 
        toggleSidebar={toggleSidebar} 
        meetingId={meetingId}
        accessToken={accessToken}
        myTeam={myTeam}
        startScreenSharing={startScreenSharing}
        stopScreenSharing={stopScreenSharing}
        /> 
        : null}
      {!dualMeetMode && antdNotificationContextHolder}
      {/* {showMutedAlert &&
        <Alert
          message="Error Text"
          description="Error Description Error Description Error Description Error Description Error Description Error Description"
          type="error"
          closable
          onClose={() => handleMutedAlertClose}
        />} */}
      {!dualMeetMode ?
        isValidMeet ? validMeet : invalidMeet
      :null}




      
    </>
  );
};

export default Room;
